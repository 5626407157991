.ant-form {
  &-item {
    @apply block mb-4;

    &-explain {
      @apply text-red-error;
    }

    &-label {
      label {
        @apply block font-bold text-base mb-1;
      }
    }

    &-control {
      &-input {
        input,
        textarea {
          @apply block py-2 px-3 w-full bg-white bg-clip-padding appearance-none rounded-none transition duration-150 ease-in-out border border-grey-text;
          &:focus {
            outline: 0;
            @apply border border-espg-night-blue;
          }
        }
      }
    }
  }
}
