h2,
h3 {
  @apply text-espg-cobalt-blue;
}

h2 {
  @apply mb-4 mt-16;
  &:first-child {
    @apply mt-0;
  }
}

h3 {
  @apply mb-4 mt-8;
  &:first-child {
    @apply mt-0;
  }
}

h4 {
  @apply mb-2 mt-6;
}

p {
  @apply text-grey-text mb-5 text-justify;
}

section {
  @apply mb-16;
}
