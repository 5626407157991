.swiper {
  @apply w-full relative;
  height: 450px;

  &-slide {
    img {
      @apply object-cover w-full h-full;
    }
  }

  &-button {
    &-prev,
    &-next {
      @apply absolute top-0 bottom-0 mt-0 h-full opacity-70 bg-gradient-to-r from-transparent to-transparent text-transparent;
      transition: 0.5ms ease-in-out opacity;
      width: 4em;

      &:hover {
        @apply text-white opacity-100;
      }
    }

    &-prev {
      @apply left-0;
      &:hover {
        @apply bg-gradient-to-r from-dark-background to-transparent;
      }
    }

    &-next {
      @apply right-0;
      &:hover {
        @apply bg-gradient-to-r from-transparent to-dark-background;
      }
    }
  }
}

@screen lg {
  .swiper {
    height: 600px;
  }
}
