@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@import "./vendors/bootstrap";
@import "./vendors/tailwind";
@import "./vendors/ngzorro";
@import "./vendors/swiper";

@import "./base/base";
@import "./base/typography";

@import "./components/button";
@import "./components/carousel";
@import "./components/content-body";
@import "./components/card";
@import "./components/form";
@import "./components/tabs";
