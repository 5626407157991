.ant-btn {
  outline: 0;
  @apply inline-block text-center py-2 px-8 rounded-sm transition duration-150 ease-in-out;

  &-primary {
    @apply bg-espg-cobalt-blue text-white text-base border border-espg-cobalt-blue;

    &:hover {
      @apply border border-espg-cobalt-blue text-espg-cobalt-blue bg-white;
    }
  }

  &-link {
    @apply text-espg-night-blue bg-transparent font-bold px-0;
    &:hover {
      @apply text-espg-golden;
    }
  }
}
