.ant-tabs {
  &-ink-bar {
    @apply bg-espg-golden;
    height: 3px;
  }

  &-tabpane {
    @apply py-8;
  }
}

.ant-tabs-nav {
  @apply w-full;

  .ant-tabs-tab {
    @apply text-grey-text bg-white text-center mr-0 py-4 px-8;
    &:hover {
      @apply text-espg-golden;
    }
  }

  .ant-tabs-tab-active {
    @apply text-espg-cobalt-blue;
  }
}
