.ant-card {
  @apply mt-2 ml-0 mb-4 mr-0 rounded-sm bg-white block;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);

  &-cover {
    height: 250px;
    img,
    object {
      @apply object-cover h-full w-full;
    }
  }

  &-body {
    @apply p-6;
  }

  &-meta {
    &-title {
      @apply text-espg-cobalt-blue text-lg font-bold mb-2;
    }
    &-description {
      p {
        @apply text-left;

        &:last-child {
          @apply mb-0;
        }
      }
    }
  }
}

.card-container {
  @apply grid grid-cols-1 gap-4;
}

@screen md {
  .card-container {
    @apply grid grid-cols-2;
  }
}

@screen lg {
  .card-container {
    @apply grid grid-cols-3;
  }
}

@screen xl {
  .card-container {
    @apply grid grid-cols-4;
  }
}
